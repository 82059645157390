<template>
  <v-icon :color="calculatedColor" :size="size">
    {{calculatedIcon}}
  </v-icon>
</template>

<script>
export default {
  name: 'TaskTimeOfDayIcon',
  props: {
    timeOfDay: String,
    size: {
      type: Number,
      default: () => 14
    }
  },
  computed: {
    calculatedIcon () {
      switch (this.timeOfDay) {
        case 'morning':
          return 'mdi-weather-sunset-up'
        case 'midday':
          return 'mdi-weather-sunny'
        case 'evening':
          return 'mdi-weather-sunset'
        default:
          return ''
      }
    },
    calculatedColor () {
      switch (this.timeOfDay) {
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
