<template>
  <v-list-item style="background-color: white;">
    <v-list-item-action class="mr-4">
      <v-checkbox :color="impactColor" v-model="task.complete" @change="updateComplete(task)"></v-checkbox>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title @click="showDetails(task)">
        <span :class="{ complete: task.complete }">
          {{task.title}}
        </span>
        <sup>
          <v-progress-circular width="3" size="12" rotate="-90" :value="calculatedEffort" :color="colorByEffort"></v-progress-circular>
          <v-icon class="ml-1" dense light small>{{timeOfDayIcon}}</v-icon>
        </sup>
      </v-list-item-title>
      <v-list-item-subtitle v-if="!task.daily && task.tags && task.tags.length > 0">
        <v-chip class="mr-1" @click.stop="selectTag(tag)" dark x-small :color="tag.color + ' lighten-4'" v-for="tag in tagsForTask(task)" :key="tag.id">{{tag.text}}</v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <p-velocity @updateVelocity="updateVelocity" v-if="task.daily" color="grey darken-2" :all-days="task.allDays" :completed-days="task.completedDays" />
      <v-icon v-if="!task.daily" class="handle" color="grey lighten-2">mdi-menu</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import taskApi from '@/firebase/taskApi'
import dateTimeUtils from '@/components/services/dateTimeUtils'
import PVelocity from '@/components/icons/PVelocity'
import tagApi from '@/firebase/tagApi'
import tagCoordinator from '@/components/services/TagCoordinator'

const updateIfDailyAndNotCompletedToday = (task) => {
  if (task.complete && task.completedOn.toDate().getTime() !== dateTimeUtils.getTimelessToday().getTime()) {
    task.complete = false
    return true
  } else {
    return false
  }
}

export default {
  name: 'TaskListItem',
  components: { PVelocity },
  props: {
    task: Object
  },
  data: () => ({
    tools: false
  }),
  watch: {
    task: {
      handler (taskToBind) {
        if (taskToBind.daily) {
          let saveTask = false
          saveTask = updateIfDailyAndNotCompletedToday(taskToBind)
          saveTask = saveTask || this.updateVelocityIfNotCalculatedToday(taskToBind)
          if (saveTask) taskApi.saveTask(taskToBind)
        }
      },
      immediate: true
    }
  },
  computed: {
    impactColor () {
      switch (this.task.impact) {
        case 'positive':
          return 'green'
        case 'negative':
          return 'orange'
        default:
          return 'primary'
      }
    },
    calculatedEffort () {
      const effortAsNumber = Number(this.task.effort || 0)
      const totalPossible = 240
      return (100 / totalPossible) * effortAsNumber
    },
    colorByEffort () {
      const effortAsNumber = Number(this.task.effort || 0)
      if (effortAsNumber <= 30) return 'green'
      if (effortAsNumber <= 60) return 'orange'
      if (effortAsNumber <= 120) return 'red lighten-2'
      else return 'red'
    },
    timeOfDayIcon () {
      switch (this.task.timeOfDay) {
        case 'morning':
          return 'mdi-weather-sunset-up'
        case 'midday':
          return 'mdi-weather-sunny'
        case 'evening':
          return 'mdi-weather-sunset'
        default:
          return ''
      }
    }
  },
  methods: {
    tagsForTask (task) {
      return task.tags.map(t => tagApi.tags.find(tag => tag.text === t))
    },
    showDetails (task) {
      this.$emit('taskDetails', { task })
    },
    updateComplete (task) {
      if (task.complete) {
        task.completedOn = dateTimeUtils.getTimelessToday()
      }
      if (task.daily) {
        task.completedDays = (task.completedDays || 0) + (task.complete ? 1 : -1)
        task.allDays += task.complete ? 1 : -1
        if (task.completedDays > 7) task.completedDays = 7
        if (task.allDays > 7) task.allDays = 7
        if (task.complete) {
          this.$emit('dailyTaskCompleted')
        }
        if (task.allDays < task.completedDays) {
          task.allDays = task.completedDays
        }
      }
      taskApi.saveTask(task)
    },
    updateVelocity (velocityInfo) {
      this.task.allDays = velocityInfo.allDays
      this.task.completedDays = velocityInfo.completedDays
      taskApi.saveTask(this.task)
    },
    getActiveDaysFrom (fromDate) {
      const daysBetweenNowAndFromDate = (dateTimeUtils.getTimelessToday().getTime() - fromDate) / (1000 * 60 * 60 * 24)
      if (!this.task.dailyDays || this.task.dailyDays.length === 7) {
        return daysBetweenNowAndFromDate
      } else {
        const dateFromFromDate = new Date(fromDate)
        let totalDays = 0
        while (dateFromFromDate < dateTimeUtils.getTimelessToday()) {
          if (this.task.dailyDays.includes(dateFromFromDate.getDay())) {
            totalDays++
          }
          dateFromFromDate.setDate(dateFromFromDate.getDate() + 1)
        }
        return totalDays
      }
    },
    selectTag (tag) {
      tagCoordinator.selectTag(tag)
    },
    newEvent (task) {

    },
    updateVelocityIfNotCalculatedToday (task) {
      if (dateTimeUtils.ensureDate(task.lastCalculated) < dateTimeUtils.getTimelessToday()) {
        // we must CALCULATE
        const daysSinceCalculation = this.getActiveDaysFrom(dateTimeUtils.ensureDate(task.lastCalculated))
        task.allDays += daysSinceCalculation - 1 // don't take today into account
        if (task.allDays > 7) {
          task.completedDays -= task.allDays - 7
          task.allDays = 7
        }
        task.lastCalculated = dateTimeUtils.getTimelessToday()
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.complete{
  text-decoration: line-through;
  color: lightgray
}
</style>
