<template>
  <v-icon :color="calculatedColor" :size="size">
    {{calculatedIcon}}
  </v-icon>
</template>

<script>
export default {
  name: 'TaskImpactIcon',
  props: {
    impact: String,
    size: {
      type: Number,
      default: () => 14
    }
  },
  computed: {
    calculatedColor () {
      switch (this.impact) {
        case 'positive':
          return 'green'
        case 'negative':
          return 'orange'
        default:
          return 'grey'
      }
    },
    calculatedIcon () {
      switch (this.impact) {
        case 'positive':
          return 'mdi-emoticon-happy'
        case 'neutral':
          return 'mdi-emoticon-neutral'
        case 'negative':
          return 'mdi-emoticon-sad'
        default:
          return 'mdi-emoticon-neutral'
      }
    }
  }
}
</script>

<style scoped>

</style>
