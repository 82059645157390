<template>
  <v-menu left :close-on-content-click="false" nudge-left="45">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" :size="size" :color="color">{{velocityIcon}}</v-icon>
    </template>
    <v-card class="pa-2 overflow-hidden" max-width="200" max-height="90">
      <v-row>
        <v-col cols="8" style="padding-top: 14px;" class="pb-0">
          Days Passed
        </v-col>
        <v-col cols="4" class="pb-0">
          <v-text-field type="number" dense class="ma-0 pa-0" @change="requestUpdate" v-model="allDaysFromForm"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" style="padding-top: 14px;" class="pt-0">
          Days Complete
        </v-col>
        <v-col class="pt-0">
          <v-text-field type="number" dense class="ma-0 pa-0" @change="requestUpdate" v-model="completedDaysFromForm"></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'PVelocity',
  data: () => ({
    completedDaysFromForm: 0,
    allDaysFromForm: 0,
    showing: false,
    speeds: [
      'mdi-human-handsdown',
      'mdi-human-cane',
      'mdi-walk',
      'mdi-run',
      'mdi-run-fast',
      'mdi-human-scooter',
      'mdi-bike',
      'mdi-bike-fast'
    ]
  }),
  props: {
    size: Number,
    color: String,
    allDays: Number,
    completedDays: Number
  },
  computed: {
    velocityIcon () {
      if (!this.completedDays || !this.allDays) {
        return this.speeds[0]
      } else {
        const percentComplete = this.completedDays / this.allDays
        const potentialRange = (this.completedDays < this.speeds.length ? this.completedDays : (this.speeds.length - 1))
        const speedIndex = Math.round(potentialRange * percentComplete)
        return this.speeds[speedIndex]
      }
    }
  },
  methods: {
    requestUpdate () {
      this.$emit('updateVelocity', {
        allDays: Number(this.allDaysFromForm),
        completedDays: Number(this.completedDaysFromForm)
      })
    }
  },
  watch: {
    allDays: {
      handler (newVal) {
        this.allDaysFromForm = newVal
      },
      immediate: true
    },
    completedDays: {
      handler (newVal) {
        this.completedDaysFromForm = newVal
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
