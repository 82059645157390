<template>
  <v-bottom-sheet max-width="600" v-model="showing">
    <v-card tile>
      <v-card-title >
        <v-row dense class="align-content-stretch">
          <v-col class="flex-grow-0">
            <v-checkbox class="ma-0 mt-1 pa-0" v-model="task.complete" @change="toggleCompletion"></v-checkbox>
          </v-col>
          <v-col class="flex-grow-1 text-break">
            <div>{{task.title}}</div>
            <v-chip-group>
              <v-chip x-small dark label :color="`${findTagColor(tag)} lighten-3`" active :ripple="false" v-for="tag in task.tags" :key="tag.id">
                {{tag}}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col class="flex-grow-0 d-flex align-start">
            <v-dialog max-width="200" v-model="selectingImpact">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <task-impact-icon :impact="task.impact" :size="34" />
                </div>
              </template>
              <v-btn-toggle color="primary" class="d-flex">
                <v-btn class="flex-grow-1" @click="setImpact('positive')">
                  <v-icon color="green" large>mdi-emoticon-happy</v-icon>
                </v-btn>
                <v-btn class="flex-grow-1" @click="setImpact('neutral')">
                  <v-icon color="orange" large>mdi-emoticon-neutral</v-icon>
                </v-btn>
                <v-btn class="flex-grow-1" @click="setImpact('negative')">
                  <v-icon color="red" large>mdi-emoticon-sad</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-dialog>
            <v-icon class="ml-2" style="margin-top: 5px;" @click.stop="timing = !timing">mdi-alarm-check</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ml-9">
        <v-dialog v-model="settingDateEffort">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-progress-circular class="mr-2" rotate="-90" color="orange" width="6" size="60" :value="effortAsPercent">
                {{effortDisplay}}
              </v-progress-circular>
              {{scheduleInfoDisplay}}
              <task-time-of-day-icon v-if="task.timeOfDay !== 'any'" :time-of-day="task.timeOfDay" />
              <v-progress-circular v-if="timing || task.timedDuration" style="position:absolute;left:66px;bottom:79px" size="48" :value="timePassed" :color="colorFromComparison" rotate="-90"></v-progress-circular>
            </div>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <p-date-picker v-model="taskDate" />
              <v-btn-toggle class="d-flex" color="primary" dense v-model="task.timeOfDay" v-if="!task.daily">
                <v-btn class="flex-grow-1" value="any" small>Any Time</v-btn>
                <v-btn class="flex-grow-1" value="morning" small>Morning</v-btn>
                <v-btn class="flex-grow-1" value="midday" small>Mid-Day</v-btn>
                <v-btn class="flex-grow-1" value="evening" small>Evening</v-btn>
              </v-btn-toggle>
              <v-slider class="pt-11" :tick-labels="['0:15', '', '', '1:00', '', '', '', '2:00', '', '', '', '3:00', '', '', '', '4:00']" v-model="task.effort" thumb-label="always" dense ticks color="primary" label="Effort" step="15" min="15" max="240">
                <template v-slot:thumb-label>
                  {{effortDisplayText}}
                </template>
              </v-slider>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" @click="saveTaskDateEffort">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" @click="requestEdit(task)">Edit</v-btn>
        <v-spacer></v-spacer>
        <v-dialog persistent max-width="400" v-model="confirming">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" outlined dark v-on="on" v-bind="attrs">Delete</v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="orange">
              Delete Task?
              <v-spacer></v-spacer>
              <v-icon>mdi-alert</v-icon>
            </v-toolbar>
            <v-card-text class="pt-5">
              Are you sure you want to delete this task?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="error" dark @click="requestDelete(task); confirming = false">Yes</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="confirming = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import taskApi from '@/firebase/taskApi'
import dateTimeUtils from '@/components/services/dateTimeUtils'
import TaskImpactIcon from '@/components/icons/TaskImpactIcon'
import TaskTimeOfDayIcon from '@/components/icons/TaskTimeOfDayIcon'
import firebase from 'firebase/app'
import PDatePicker from '@/components/inputs/PDatePicker'
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'

export default {
  name: 'TaskDetails',
  components: { PDatePicker, TaskTimeOfDayIcon, TaskImpactIcon },
  props: {
    task: Object,
    trigger: Boolean
  },
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('tags', db.collection(`users/${user.id}/tags`).orderBy('order'))
      this.user = user
    })
  },
  data: () => ({
    showing: false,
    confirming: false,
    schedulingTask: false,
    selectingImpact: false,
    settingDateEffort: false,
    taskDate: null,
    tags: [],
    timing: false,
    startTime: null,
    timer: null,
    seconds: 0
  }),
  computed: {
    scheduleInfoDisplay () {
      return dateTimeUtils.toTaskScheduleDisplay(this.task)
    },
    effortAsPercent () {
      const effortAsNumber = Number(this.task.effort || 0)
      const totalPossible = 240
      return (100 / totalPossible) * effortAsNumber
    },
    effortDisplay () {
      return dateTimeUtils.toEffortDisplay(this.task.effort)
    },
    timeOfDayText () {
      switch (this.task.timeOfDay) {
        case 'morning':
          return 'Morning'
        case 'midday':
          return 'Mid-Day'
        case 'evening':
          return 'Evening'
        default:
          return 'Any Time of the Day'
      }
    },
    effortDisplayText () {
      return dateTimeUtils.toEffortDisplay(this.task.effort)
    },
    timePassed () {
      console.log('calculating time')
      if (this.startTime && this.seconds) {
        return Math.floor((new Date() - this.startTime + (this.task.timedDuration || 0)) / 1000 / 60)
      } else {
        return 0
      }
    },
    colorFromComparison () {
      return this.timePassed > this.task.effort ? 'red' : 'green'
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  watch: {
    timing (on) {
      if (on) {
        this.startTime = new Date()
        this.timer = setInterval(() => {
          this.seconds++
        }, 1000)
      } else {
        clearInterval(this.timer)
        this.task.timedDuration = new Date() - this.startTime + (this.task.timedDuration || 0)
        taskApi.saveTask(this.task)
      }
    },
    trigger (on) {
      this.showing = on
    },
    showing (open) {
      if (!open) this.$emit('closeRequested')
    },
    task (taskToBind) {
      this.taskDate = taskToBind && taskToBind.date ? dateTimeUtils.ensureDate(taskToBind.date) : null
    }
  },
  methods: {
    findTagColor (tag) {
      return (this.tags.find(t => t.text === tag) || { color: 'blue' }).color
    },
    requestEdit (task) {
      this.$emit('editRequested', task)
    },
    requestDelete (task) {
      this.$emit('deleteRequested', task)
    },
    async toggleCompletion () {
      await taskApi.saveTask(this.task)
    },
    async setImpact (impact) {
      this.task.impact = impact
      await taskApi.saveTask(this.task)
      this.selectingImpact = false
    },
    async saveTaskDateEffort () {
      this.task.date = this.taskDate ? this.taskDate : firebase.firestore.FieldValue.delete()
      await taskApi.saveTask(this.task)
      this.task.date = this.taskDate ? this.taskDate : null
      this.settingDateEffort = false
    }
  }
}
</script>

<style scoped>

</style>
